<template>
  <div class="page">
    <el-form class="query-form" size="small" :inline="true" ref="searchForm" :model="searchForm"
             label-width="100px" @keyup.enter.native="search(1)">
      <el-form-item prop="incidentDescription" label="业务描述：">
        <el-input v-model.trim="searchForm.incidentDescription" maxlength="50" placeholder="请输入业务描述"
                  clearable></el-input>
      </el-form-item>
      <el-form-item prop="createStartDate" label="创建时间：">
        <el-date-picker v-model="createDate" value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        type="daterange" clearable start-placeholder="开始时间"
                        end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="localSystem" label="所在系统：">
        <el-select v-model="searchForm.localSystem" filterable placeholder="请选择" clearable>
          <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in $dictUtils.getDictList('local_system')">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="businessType" label="业务模块：">
        <el-select v-model="searchForm.businessType" filterable placeholder="请选择" clearable>
          <el-option
              :key="item.value"
              :label="item.label"
              :value="item.value"
              v-for="item in $dictUtils.getDictList('business_module')">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
        <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="bg-white">
      <el-tabs v-model="searchForm.eventStatus" size="small" @tab-click="search(1)">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.label"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
      <el-table
          :data="dataList"
          v-loading="loading"
          size="small"
          height="calc(100vh - 401px)"
          class="table">
        <el-table-column type="index" label="序号"/>
        <el-table-column prop="incidentDescription" label="业务描述" show-overflow-tooltip/>
        <el-table-column prop="incidentStatus" label="事件状态" show-overflow-tooltip></el-table-column>
        <el-table-column prop="businessType" label="业务模块" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("business_module", scope.row.businessType, '') }}
          </template>
        </el-table-column>
        <el-table-column prop="localSystem" label="所在系统" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $dictUtils.getDictLabel("local_system", scope.row.localSystem, '') }}
          </template>
        </el-table-column>
        <el-table-column prop="createName" label="执行人" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createDate" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="updateDate" label="修改时间" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small"
                       v-if="hasPermission('workbench:backlog:dispose') && searchForm.eventStatus == '0'"
                       @click="toDispose(scope.row)">处理
            </el-button>
            <el-button type="text" size="small"
                       v-if="hasPermission('workbench:backlog:view') && searchForm.eventStatus == '1'"
                       @click="toDispose(scope.row,1)">详情
            </el-button>
            <el-button type="text" size="small"
                       v-if="hasPermission('workbench:backlog:del') && searchForm.eventStatus == '1'"
                       @click="delMatter(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="searchForm.current"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchForm.size"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <AuditorForm ref="auditorForm" @refreshDataList="search()"></AuditorForm>
    <LendAuditorForm ref="lendAuditorForm" @refreshDataList="search()"></LendAuditorForm>
    <InteriorAuditorForm ref="interiorAuditorForm" @refreshDataList="search()"></InteriorAuditorForm>
    <ViewThreeForm ref="viewThreeForm" @refreshDataList="search()"></ViewThreeForm>
    <ViewForm ref="viewForm" @refreshDataList="search()"></ViewForm>
    <coll-list ref="collList" @refresh="search()"></coll-list>
  </div>
</template>

<script>
import AuditorForm from '../record/borrow/electronic/auditorForm'
import LendAuditorForm from '../record/borrow/entity/lend/auditorForm'
import InteriorAuditorForm from '../record/borrow/entity/interior/auditorForm'
import ViewThreeForm from '../dyyg/gather/resource/three/viewThreeForm' // 三维查看弹窗
import ViewForm from '../dyyg/gather/resource/components/viewForm'
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue"; // 查看弹窗

export default {
  components: {CollList, ViewThreeForm, ViewForm, AuditorForm, LendAuditorForm, InteriorAuditorForm},
  data() {
    return {
      searchForm: {
        incidentDescription: '',
        createStartDate: '',
        createEndDate: '',
        localSystem: '',
        businessType: '',
        eventStatus: '0',
        current: 1,
        size: 10,
      },
      createDate: [],
      tabsList: [
        {
          value: '0',
          label: '待办'
        },
        {
          value: '1',
          label: '已办'
        }
      ],
      loading: false,
      dataList: [],
      current2: 0,
      total: 0,
    }
  },
  mounted() {
    let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
    if (listSearch) {
      this.searchForm = listSearch.searchForm
      this.createDate = listSearch.createDate
      this.current2 = listSearch.searchForm.current
    }
    this.search()
  },
  methods: {
    // 查询
    search(type) {
      if (type == 1) {
        this.searchForm.current = 1
      }
      this.loading = true
      let p = JSON.parse(JSON.stringify(this.searchForm))
      p.createStartDate = this.createDate ? this.createDate[0] : ''
      p.createEndDate = this.createDate ? this.createDate[1] : ''
      if (this.current2) {
        p.current = this.current2
      }
      this.$axios(this.api.allCommon.commonBacklog, p).then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          if (this.dataList.length == 0 && this.searchForm.current > 1) {
            this.searchForm.current--
            this.search()
          }
          this.total = parseInt(res.data.total)
          let listSearch = JSON.parse(sessionStorage.getItem('listSearch'))
          if (listSearch) {
            this.current = listSearch.searchForm.current
            this.current2 = 0
            sessionStorage.removeItem('listSearch')
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },
    // 去处理
    toDispose(row, num) {
      let see = true
      if (num) {
        see = num
      }
      if (row.businessType != 16 && row.businessType != 17 && row.businessType != 18 && row.businessType != 21) {
        //缓存搜索信息
        let listSearch = {
          searchForm: this.searchForm,
          createDate: this.createDate,
        }
        sessionStorage.setItem('listSearch', JSON.stringify(listSearch))
      }
      row.id = row.businessId
      if (row.businessType == 0) { //藏品登记审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'toExamine',
            see: see
          }
        })
      }
      if (row.businessType == 1) { //辅助账审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'accountExamine',
            see: see
          }
        })
      }
      if (row.businessType == 2) { //藏品总账审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 1,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'ledgerReview',
            see: see
          }
        })
      }
      if (row.businessType == 3) { //征集登记审核
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: 5, pageSource: 'toExamine', id: row.id, see: see}
        })
      }
      if (row.businessType == 4) { //征集入馆审核
        this.$router.push({
          path: '/collection/accounts/addCollection',
          query: {
            butType: 8,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'entryReview',
            see: see
          }
        })
      }
      if (row.businessType == 5) { //不可移动文物审核
        this.$router.push({
          path: '/collection/immovable/addMovable',
          query: {butType: 3, id: row.id, typePage: 'ImmovableAuditing', see: see}
        })
      }
      if (row.businessType == 6) { //总账注销审核
        this.$router.push({
          path: '/collection/business/collectionDet',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'cancellationReview',
            see: see
          }
        })
      }
      if (row.businessType == 7) { //辅助账注销审核
        this.$router.push({
          path: '/collection/business/collectionDet',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'auxiliaryExamine',
            see: see
          }
        })
      }
      if (row.businessType == 8) { //展览审核
        this.$router.push({
          path: '/collection/business/exhibitionDetail',
          query: {butType: 4, exhibitionId: row.id, see: see},
        })
      }
      if (row.businessType == 9) { //藏品鉴定专家审核
        this.$router.push({
          path: '/collection/business/expertDatabase/addAppraisal',
          query: {butType: 4, expertId: row.id, see: see},
        })
      }
      if (row.businessType == 10) { //藏品鉴定登记审核
        this.$router.push({
          path: '/collection/business/collIdentification/addIdentify',
          query: {butType: 4, appraisalId: row.id, see: see},
        })
      }
      if (row.businessType == 11) { //修复登记审核
        this.$router.push({
          path: '/collection/business/repair/addRepair',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 12) { //事故登记审核
        this.$router.push({
          path: '/collection/business/fault/addFault',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 13) { //复仿制品登记审核
        this.$router.push({
          path: '/collection/business/products/addProducts',
          query: {
            butType: 3,
            id: row.id,
            archivesBasicDataId: row.archivesBasicDataId,
            typePage: 'productsExamine',
            see: see
          }
        })
      }
      if (row.businessType == 14) { //藏品装裱登记审核
        this.$router.push({
          path: '/collection/business/mount/addMount',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 15) { //保护情况登记审核
        this.$router.push({
          path: '/collection/business/protect/addProtect',
          query: {butType: 4, id: row.id, see: see},
        })
      }
      if (row.businessType == 16) { //电子借阅审核
        this.$refs.auditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 17) { //借出利用审核
        this.$refs.lendAuditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 18) { //内部利用审核
        this.$refs.interiorAuditorForm.init(row.businessId, [], 'workbench')
      }
      if (row.businessType == 19) { //入库审核
        this.$router.push({
          path: '/collection/business/warehousing/shenDetail',
          query: {butType: 4, handleType: 1, id: row.id, switch: 1, see: see}
        })
      }
      if (row.businessType == 20) { //出库审核
        this.$router.push({
          path: '/collection/business/outBound/addOutbound',
          query: {butType: 4, id: row.id, switch: '1', see: see}
        })
      }
      if (row.businessType == 21) { //资产审核
        if (row.resourceType == 0) {
          this.$refs.viewThreeForm.init(row, null, null, num ? '' : 'workbench');
        } else {
          this.$refs.viewForm.init(row.id, row.resourceType, '958321248549470208', null, null, num ? '' : 'workbench');
        }
      }
      if (row.businessType == 22) { //专题库审核
        this.$refs.collList.init(2, row,'','','backlog',see)
      }
    },
    // 删除
    delMatter(id) {
      this.$confirm(`确定删除所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.allCommon.commonBacklogRemoveById + '/' + id, {}, 'delete').then(data => {
          if (data.status) {
            this.$message.success('删除成功')
            this.search()
          } else {
            this.$message.error('删除失败')
          }
        })
      })
    },
    // 重置
    reset() {
      this.$refs.searchForm.resetFields();
      this.createDate = []
      this.search(1)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.search()
    },
    // 当前页
    currentChangeHandle(val) {
      this.searchForm.current = val
      this.search()
    },
  },
}
</script>

<style scoped>

</style>
